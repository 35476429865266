import React from 'react';
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';



export const Item_A = React.memo(({ part, InputChange, textStates, textHandlers }) => {
  
  // console.log("ナビバーパーツレンダ");
  
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers

  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const itemCSS = css`
    ${outlineCSS(part)}
    
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
      background: ${accentColors[0]};
      border-radius: ${part.radius && `${part.radius}px`};
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
    
    ${part.class_name == "item_2-1" && `
      .title {
        border-radius: 0;
      }
    `}
  `
  
  
  return (
    <>
      <div
        className={`item_1 ${part?.class_name} ${part?.add_class}`}
        css={itemCSS}
        id={part.add_id}
      >
        <div className="subtitle">
          <Textarea 
            part_id={part.id}
            text_type="subtitle"
            obj={subtitle}
            setObj={setSubtitle}
            placeholder="2023年10月30日"
            clickHandler={subtitleHandler}
          />
        </div>
        
        <div className="title">
          <Textarea
            part_id={part.id}
            text_type="title"
            obj={title}
            setObj={setTitle}
            placeholder="項目名"
            clickHandler={titleHandler}
          />
        </div>
        
        <p>
          <Textarea
            part_id={part.id}
            text_type="content"
            obj={content}
            setObj={setContent}
            placeholder="内容を入力..."
            clickHandler={contentHandler}
          />
        </p>
        
      </div>
    </>
  );
  
});