import React from "react"
// import TextareaAutosize from 'react-textarea-autosize';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { outlineCSS, textCSS } from '@/part/type/Style';
import { Textarea } from '@/text/Textarea';
import { BiChevronRight } from "react-icons/bi";


export const Button_A = React.memo(({ part, InputChange, textStates, textHandlers }) => {
  
  // console.log("ボタンパーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const buttonCSS = css`
    font-family: ${part.font};
    
    button {
      ${outlineCSS(part)}
      ${textCSS(title)}
      outline-color: ${part.class_name == "button_2-3" && `${accentColors[0]}`};
      box-shadow: ${part.class_name == "button_2-5" && `2px 4px 0 1px ${accentColors[0]}`};
    }
    .subtitle {
      ${textCSS(subtitle)}
      ${part.class_name == "button_4-1" && `
        border-color: ${part.border_color};
        background: ${accentColors[0]};
        ${accentColors[1] && `
          background: linear-gradient(130deg, ${accentColors[0]} 30%, ${accentColors[1]} 100%);
        `}
      `}
    }
    .microcopy {
      ${textCSS(content)}
    }
  `
  
  return (
    <>
      <div
        className={`button_1 ${part.class_name} ${part.add_class||""}`}
        css={buttonCSS}
        id={part.add_id}
      >
        <div className="subtitle">
         <Textarea
            part_id={part.id}
            text_type="subtitle"
            obj={subtitle}
            setObj={setSubtitle}
            placeholder="＼PAGE STOCK／"
            clickHandler={subtitleHandler}
          />
        </div>
        
        <button>
          <Textarea
            part_id={part.id}
            text_type="title"
            obj={title}
            setObj={setTitle}
            placeholder="ボタン"
            clickHandler={titleHandler}
          />
          <BiChevronRight/>
        </button>
        
        <div className="microcopy">
          <Textarea
            part_id={part.id}
            text_type="content"
            obj={content}
            setObj={setContent}
            placeholder="マイクロコピーを入力..."
            clickHandler={contentHandler}
          />
        </div>
      </div>
    </>
  );
  
});


// export const Button_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("ボタンパーツレンダ");
  
  
//   const buttonCSS = css`
//     button {
//       ${outlineCSS(part)}
//       ${fontCSS(part)}
//     }
//     .subtitle, .microcopy {
//       color: ${part.border_color};
//       font-family: ${part.font};
//     }
//     .subtitle {
//       color: ${part.sub_color};
//     }
//   `
  
//   return (
//     <>
//       <div
//         className={`button_1 ${part.class_name} ${part.add_class||""}`}
//         css={buttonCSS}
//         id={part.add_id}
//       >
//         <div className="subtitle">
//           <input 
//             type="text" 
//             name="subtitle" 
//             value={part.subtitle || ''} 
//             onChange={InputChange}
//             placeholder="＼はじめてのホームページ作成に最適／"
//           />
//         </div>
        
//         <button>
//           <TextareaAutosize
//             type="text" 
//             name="title" 
//             value={part.title ||''}
//             onChange={InputChange} 
//             placeholder="お問い合わせ"
//           />
//         </button>
        
//         <div className="microcopy">
//           <TextareaAutosize
//             type="text"
//             name="content" 
//             value={part.content || ''} 
//             onChange={InputChange} 
//             placeholder="ページストックを「無料」ではじめる"
//           />
//         </div>
//       </div>
//     </>
//   );
  
// });