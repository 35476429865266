import React, { useState, useEffect, useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { useParams } from 'react-router-dom';
// import { MenuSelect } from '@/part/MenuSelect';
// import { placeholder } from '@/app/DefaultData';
import { BiMenu, BiX } from "react-icons/bi";

import { backgroundCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';
// import { ImgSetting } from '@/image/ImgSetting';

import { useSetRecoilState } from 'recoil'
import { openSidebarAtom, partImgStatesAtom } from '@/providers/Atoms';


export const Navbar_A = React.memo(({ part, imgs, InputChange, openState, Clear,
  textStates, textHandlers }) => {
    
  const { productId } = useParams();
  
  // console.log("ナビバーパーツレンダ");
  const setPartImgStates = useSetRecoilState(partImgStatesAtom);
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  
  const { title, setTitle, subtitle, setSubtitle } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  // const [ spSwitch, setSpSwitch ] = useState("BASE");
  // const spSwitchState = { spSwitch, setSpSwitch }
  
  // const { open, setOpen } = openState;
  
  const { client } = useContext(StateContext);
  const [ items, setItems ] = useState([]);
  const [ drawerItems, setDrawerItems ] = useState([]);
  
  // console.log("items", items);
  // console.log("drawerItems", drawerItems);
  
  // const GetMenu = (id) => {
  //   if(part?.menu_id !== null){
  //     client.get(`/api/v1/menus/${id}`)
  //     .then(resp => {
  //       setItems(resp.data.items);
  //       console.log("items", resp.data.items);
  //     }) 
  //   }
  // };

  // useEffect(() => {
  //   GetMenu(part.menu_id);
  // }, []);
  

  useEffect(() => {
    client.get(`/api/v1/menus/navbar/${part.menu_id}?subdomain=${productId}&part_id=${part.id}`)
    .then(resp => {
      setItems(resp.data.items || []);
      setDrawerItems(resp.data.drawer_items || resp.data.items || []);
    })
  }, [part.menu_id]);
  
  
  const imgHandler = ()=> {
    setOpenSidebar("partImg");
    setPartImgStates([ img_0, setImg_0 ]);
  };
  
  // const [ active, setActive ] = useState(false);
  
  const menuSelectHandler = () => {
    setOpenSidebar("partMenu");
  };
  
  
  const [ menuOpen, setMenuOpen ] = useState(false);
  const OpenToggle = ()=> {
    setMenuOpen((prev)=> !prev);
  };

  
  // let fontColors = title.font_color ? title.font_color.split("／") : [];
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];

  const nabvarCSS = css`
    font-family: ${part.font};
    height: ${part.height ? "100%" : "50px"};
    border-radius: ${part.radius && `${part.radius}px`};
    padding: ${part.padding && `${part.padding}px`};
    ${backgroundCSS(part)}
    
    .nav_logo {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .nav_title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .nav_inner {
      justify-content: ${part.align};
    }
    .item_text {
      color: ${part.font_color};
    }
    .nav_item:before {
      background: ${part.font_color};
    }
    .nav_btn {
      border-color: ${part.border_color};
      border-width : ${part.border && `${part.border}px`};
      border-radius: ${part.radius && `${part.radius}px`};
      ${textCSS(subtitle)}
      
      background: ${accentColors[0]};
      ${accentColors[1] && `
        background: linear-gradient(130deg, ${accentColors[0]} 35%, ${accentColors[1]} 100%);
      `}
    }
    .nav_menu_btn {
      color: ${part.font_color};
    }
    .drawer_menu_btn span {
      background: ${part.font_color};
    }
    
    ${part.class_name == "navbar_3-1" && `
      height: ${part.height ? `${part.height}px` : "65px"};
      
      @container (width < 500px) {
        height: ${part.height ? `${part.height}px` : "55px"};
      }
    `}
  `
  
  // let subtitleColors = subtitle.font_color ? subtitle.font_color.split("／") : [];

  // const menuCSS = css`
  //   background: ${accentColors[0]};
  //   color: ${subtitleColors[0]};
  //   font-family: ${part.font};
  //   .close_text {
  //     border-top: solid 2px ${subtitleColors[0]};
  //   }
  // `
  
  return (
    <>
      <div className="menu_select_btn" onClick={menuSelectHandler}>メニューから選択</div>
      
      {/*
      {active &&
        <MenuSelect 
          part={part} 
          InputChange={InputChange} 
          GetMenu={GetMenu} 
          setItems={setItems} 
          Clear={Clear}
          setActive={setActive}
        />
      }
      */}

      <div
        className={`navbar_1 ${part?.class_name} ${part?.add_class || ""}`}
        css={nabvarCSS}
        id={part.add_id}
      >
        {part.class_name !== "navbar_2-2" &&
          <>
            <div className="nav_logo"
              style={{display: img_0.img ? "block" : "none"}}
            >
              <PartImg
                obj={img_0}
                setObj={setImg_0}
              />
            </div>
            
            <div className="nav_title"
              style={{display: img_0.img ? "none" : "block"}}
            >
              <Textarea
                part_id={part.id}
                text_type="title"
                obj={title}
                setObj={setTitle}
                placeholder="タイトル"
                clickHandler={titleHandler}
              />
              <div className="img_select_btn" onClick={imgHandler}>
                ロゴ画像を選択
              </div>
            </div>
          </>
        }
        
        {/* 
        { open == "Img" &&
          <ImgSetting 
            obj={img_0} 
            setObj={setImg_0}
            type="part"
            setOpen={setOpen}
            spSwitchState={spSwitchState}
          />
        }
        */}
     
        <ul className="nav_inner">
          {items.map((item) => {
            return (
              <li key={item.id}>
                <a className="nav_item">
                  <div className="item_text">{item.name}</div> 
                  { item.sub_name &&
                    <div className="item_subtext">{item.sub_name}</div> 
                  }
                </a>
              </li>
            );
          })}
        </ul>
          
        <a className="nav_btn">
          <Textarea
            part_id={part.id}
            text_type="subtitle"
            obj={subtitle}
            setObj={setSubtitle}
            placeholder="ボタン"
            clickHandler={subtitleHandler}
          />
        </a>
        
        {/* 
        <div className="nav_menu_btn" onClick={OpenToggle}>
          {menuOpen ? <BiX/> : <BiMenu/>}
        </div>
        */}
        
        <div 
          className={`drawer_menu_btn ${menuOpen && "active"}`} 
          onClick={OpenToggle}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        
      </div>
      
      <div 
        className={`drawer_menu ${menuOpen && "active"}`}
        // css={menuCSS}
      >
        <div className="close_btn" onClick={OpenToggle}></div>
        <div className="drawer_menu_title">MENU</div>
        
        <ul>
          {drawerItems.map((item) => {
            return (
              <li onClick={OpenToggle} key={item.id}>
                <a>
                  {item.name}
                  {item.sub_name && <span>{item.sub_name}</span>}
                </a>
              </li>
            );
          })}
        </ul>
        
        <div className="close_text" onClick={OpenToggle}>閉じる</div>
      </div>
      
    </>
  );
  
});

// <div className="navbar_menu_title">
//   <span>M</span>
//   <span>E</span>
//   <span>N</span>
//   <span>U</span>
// </div>


// { open == "Img" &&
//   <>
//     {spSwitch == "PC"
//       ? <ImgSetting 
//           obj={img_pc}
//           setObj={setImg_pc}
//           type="part"
//           setOpen={setOpen}
//           spSwitchState={spSwitchState}
//         />
//       : <ImgSetting 
//           obj={img_sp}
//           setObj={setImg_sp}
//           type="part"
//           setOpen={setOpen}
//           spSwitchState={spSwitchState}
//         />
//     }
//   </>
// }

  // useEffect(() => {
  //   if(part?.menu_id == null){
  //     setItems([])
  //   } else {
  //     client.get(`/api/v1/menus/navbar/${part.menu_id}?subdomain=${productId}`)
  //     .then(resp => {
  //       setItems(resp.data.items);
  //       setDrawerItems(resp.data.drawer_items || resp.data.items);
  //     })
  //   }
  // }, [part.menu_id]);