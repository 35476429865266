import React from 'react'

export const DummyForm = () => {
  

  return (
    <section id="c_section">
      <div className="wrapper">
        <div className="contact_form" id="c_form">
          <h2 className="title">お問い合わせ</h2>
          
          <label>お名前</label>
          <input 
            className="input"
            placeholder="お名前を入力..."
          />
        
          <label>メールアドレス(返信用)</label> 
          <input 
            className="input"
            placeholder="例）info@example.com"
          />
          
          <label>件名</label>
          <input 
            className="input"
            placeholder="件名を入力..."
          />
        
          <label>メッセージ内容</label>
          <textarea 
            rows="6" 
            className="input"
            placeholder="メッセージ内容を入力..."
          />
          
          <center>
            <p className="mb_2">
              お問い合わせ内容を確認し、折り返しご連絡させていただきます。<br/>
              自動返信メールが届かない場合はご入力のメールアドレスが間違っている可能性がありますのでご注意ください。
            </p>
          </center>
          
          <button className="form_btn">送信する</button>
        </div>
      </div>
    </section>
  );

};
