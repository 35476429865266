import React, { useState, useEffect, useRef } from "react"
import { RgbaStringColorPicker } from "react-colorful";
import { PresetColors } from '@/app/PresetColors';
import { RangeSpin } from '@/editerMenu/RangeSpin';
import { fontFamilys } from '@/app/DefaultData';
import { BiX } from "react-icons/bi";

import { useRecoilState, useSetRecoilState } from 'recoil'
import { textStatesAtom, openSidebarAtom } from '@/providers/Atoms';


export const TextGlobalSetting = React.memo(({ }) => {
  
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  const [ textStates, setTextStates ] = useRecoilState(textStatesAtom);
  
  const obj = textStates[0]
  const setObj = textStates[1]
  
  const setStates = (newObj) => {
    setObj(newObj);
    setTextStates([ newObj, setObj ]);
  } 
  
  // console.log("グローバルテキスト", obj);
  
  // const [ fontColor, setFontColor ] = useState("");
  // const [ subColor, setSubColor ] = useState("");
  
  // const [ colorStateSwitch, setColorStateSwitch ] = useState([fontColor, setFontColor]);
  const [ inputName, setInputName ] = useState("font_color");
  
  
  const fontColorHandler = ()=> {
    // setColorStateSwitch([fontColor, setFontColor]);
    setInputName("font_color");
  };
  
  const subColorHandler = ()=> {
    // setColorStateSwitch([subColor, setSubColor]);
    setInputName("sub_color");
  };
  
  
  let fontColors = obj.font_color ? obj.font_color.split("／") : ["",""];
  
  // useEffect(() => {
  //   setFontColor(fontColors[0] ||"");
  //   setSubColor(fontColors[1] ||"");
  // }, []);
  
  
  // // refをtrueで初期化。
  // const Reff = useRef(true);
  // // カラー変更時
  // useEffect(() => {
  //   // 初回レンダリング時はrefをfalseにして、return。
  //   if (Reff.current || obj.font_color == `${fontColor}／${subColor}`) {
  //     Reff.current = false;
  //     return;
  //   }

  //   console.log("カラー変更処理");
  //   // setObj((prev)=> ({ ...prev, font_color: `${fontColor}／${subColor}`}));
  //   const newObj = { ...obj, font_color: `${fontColor}／${subColor}`};
  //   setStates(newObj);
    
  // }, [fontColor, subColor]);

    
  let colorValue = "";
  if(inputName == "font_color"){
    colorValue = fontColors[0] ||"";
  } else if(inputName == "sub_color"){
    colorValue = fontColors[1] ||"";
  }
  
  
  // 変更カラーセット
  const colorSet = (color) => {
    let newObj;
    if(inputName == "font_color") {
      newObj = { ...obj, font_color: `${color}／${fontColors[1]}` };
    } else if(inputName == "sub_color") {
      newObj = { ...obj, font_color: `${fontColors[0]}／${color}` };
    }
    
    setStates(newObj);
  }


  const InputChange = event => {
    const { name, value } = event.target;
    // setObj((prev)=> ({ ...prev, [name]: value}));
    const newObj = { ...obj, [name]: value};
    setStates(newObj);
  };
  
  
  const colorChange = event => {
    const { name, value } = event.target;
    colorSet(value);
    
    // if (name == "font_color") {
    //   // setObj((prev)=> ({ ...prev, font_color: `${value}／${subColor}`}));
    //   const newObj = { ...obj, font_color: `${value}／${subColor}`};
    //   setStates(newObj);
    // } else if (name == "sub_color") {
    //   setObj((prev)=> ({ ...prev, font_color: `${fontColor}／${value}`}));
    // }
  };
  
  
  // カラーピッカーでの変更
  const [ colorPicker, setColorPicker ] = useState("");
  // refをtrueで初期化。
  const Reff = useRef(true);
  // カラー変更時
  useEffect(() => {
    // 初回レンダリング時はrefをfalseにして、return。
    if (Reff.current) {
      Reff.current = false;
      return;
    }

    console.log("ピッカーカラー変更処理");
    colorSet(colorPicker);

  }, [colorPicker]);
  
  
  // const PickerSwatch = (color) => {
  //   colorStateSwitch[1](color);
  // }


  // クリア
  const Clear = (column) => {
    // setObj((prev)=> ({ ...prev, [column]: null}));
    const newObj = { ...obj, [column]: null};
    setStates(newObj);
  }
  
  // カラークリア
  const coloClear = () => {
    // if(column == "font_color") {
    //   setFontColor("");
    // } else if(column == "sub_color") {
    //   setSubColor("");
    // }
    
    let newObj;
    if(inputName == "font_color") {
      newObj = { ...obj, font_color: `／${fontColors[1]}` };
    } else if(inputName == "sub_color") {
      newObj = { ...obj, font_color: `${fontColors[0]}／` };
    }
    
    setStates(newObj);
  }


  
  // フォーカスを外す
  const removeFocus = () => {
    const elem = document.activeElement;
    elem.blur();
  };
  
  
  // マウスオンで変更
  const changeOnMouse = (name, value) => {
    // setObj((prev)=> ({ ...prev, [name]: value }));
    const newObj = { ...obj, [name]: value};
    setStates(newObj);
    removeFocus();
  };
  
  
  // ドロップダウンの非表示用
  const [ none, setNone ] = useState(false);
  
  // ドロップダウンを非表示に
  const hiddenDrop = () => {
    setNone(true);
  };
  
  const fontWeightValue = [
    { name: "細字", value: "normal"},
    { name: "太字", value: "bold"},
  ]
  
  const fontSizeValue = [
    { value: 12 },
    { value: 14 },
    { value: 16 },
    { value: 18 },
    { value: 20 },
    { value: 22 },
    { value: 24 },
    { value: 26 },
    { value: 28 },
    { value: 30 },
    { value: 32 },
    { value: 34 },
    { value: 36 },
    { value: 38 },
    { value: 40 },
    { value: 42 },
    { value: 44 },
    { value: 46 },
    { value: 48 },
  ]
  
  // 閉じるボタン
  const closeHandler = ()=> {
    // setTextStates([])
    setOpenSidebar("");
  };


  return (
    <>
      <div 
        // className="cover"
        // style={{background:"transparent"}}
        // onClick={()=>setTextStates([])}
      />
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            文字の設定
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <button className="btn_line w_100 mb_2 t_view" onClick={removeFocus}>キーボードを閉じる</button>

          <div className="drop_btn range_drop">
            <label>文字サイズ：パソコン時（{obj.font_size ||""}px）
              <div className="btn_clear" type="button" onClick={()=>Clear("font_size")}>クリア</div>
            </label>
            
            <RangeSpin
              obj={obj}
              setObj={setObj}
              column="font_size"
              objValue={obj.font_size ||16}
              InputChange={InputChange}
              max="80"
            　min="10"
            　setStates={setStates}
            />
            
            {/*
            <div 
              className="drop_box" 
              style={{display: none && "none"}}
              onClick={hiddenDrop}
              onMouseLeave={()=>setNone(false)}
            >
              <div className="drop_list">
                <div className="drop_item">パソコン時</div>
                <div className="drop_item" onClick={()=>Clear("font_size")}>クリア</div>
                {fontSizeValue.map((item, index) => (
                  <div
                    className={`drop_item ${obj.font_size == item.value && "active"}`} 
                    onMouseEnter={()=>changeOnMouse("font_size", item.value)}
                    key={index}
                  >
                    {item.value}px
                  </div>
                ))}
              </div>
            </div>
            */}

          </div>
          
          <div className="drop_btn range_drop">
            <label>文字サイズ：スマホ時（{obj.font_size_s ||""}px）
              <div className="btn_clear" type="button" onClick={()=>Clear("font_size_s")}>クリア</div>
            </label>
            <RangeSpin
              obj={obj}
              setObj={setObj}
              column="font_size_s"
              objValue={obj.font_size_s ||16}
              InputChange={InputChange}
              max="60"
            　min="10"
            　setStates={setStates}
            />
            
            {/*
            <div 
              className="drop_box" 
              style={{display: none && "none"}}
              onClick={hiddenDrop}
              onMouseLeave={()=>setNone(false)}
            >
              <div className="drop_list">
                <div className="drop_item">スマホ時</div>
                <div className="drop_item" onClick={()=>Clear("font_size_s")}>クリア</div>
                {fontSizeValue.map((item, index) => (
                  <div
                    className={`drop_item ${obj.font_size_s == item.value && "active"}`} 
                    onMouseEnter={()=>changeOnMouse("font_size_s", item.value)}
                    key={index}
                  >
                    {item.value}px
                  </div>
                ))}
              </div>
            </div>
            */}
          </div>
          
          
          <label>文字のフォント
            <div className="btn_clear" type="button" onClick={()=>Clear("font")}>クリア</div>
          </label>
          <div className="selectbox">
            <select 
              className="" 
            　name="font"
            　value={obj.font || ''} 
            　onChange={InputChange}
            > 
              {fontFamilys.map((font, index) => {
                return (
                  <option value={font.value} key={index}>{font.name}</option>
                );
              })}
            </select>
          </div>
          
          <label>文字の太さ
            <div className="btn_clear" type="button" onClick={()=>Clear("font_weight")}>クリア</div>
          </label>
          <form className="radio_box">
            {fontWeightValue.map((item) => {
              return (
                <label key={item.name} className={item.value == obj.font_weight ? "is_on": ""}>
                  <input
                    type="radio"
                    id={item.name}
                    name="font_weight"
                    value={item.value}
                    onChange={InputChange}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>
          
          <label>文字の影（{obj.text_shadow ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("text_shadow")}>クリア</div>
          </label>
          <RangeSpin
            obj={obj}
            setObj={setObj}
            column="text_shadow"
            objValue={obj.text_shadow || 1}
            InputChange={InputChange}
            max="15"
            setStates={setStates}
          />
          
          <label>下側のスペース（{obj.margin ||""}px）
            <div className="btn_clear" type="button" onClick={()=>Clear("margin")}>クリア</div>
          </label>
          <RangeSpin
            obj={obj}
            setObj={setObj}
            column="margin"
            objValue={obj.margin || 10}
            InputChange={InputChange}
            setStates={setStates}
          />
          
          <label>文字の色（個別の文字に適用）</label>
          <div className="tab_menu">
            <button 
              className={`tab_menu_btn ${inputName == "font_color" && "active"}`}
              onClick={fontColorHandler}
            >メイン</button> 
            <button
              className={`tab_menu_btn ${inputName == "sub_color" && "active"}`}
              onClick={subColorHandler}
            >グラデーション</button>
          </div>
          
          {inputName == "font_color" &&
            <>
              <label>文字色（メイン）</label>
            </>
          }
          
          {inputName == "sub_color" &&
            <>
              <label>文字色（グラデーション）</label>
            </>
          }
          
          <div className="input_inline mb_1">
            <button className="color_preview_btn" 
              style={{ background: colorValue || "#fff" }}
            />
              <input
                type="text"
                className="input"
                style={{ width: "100%" }}
                name={inputName}
                value={colorValue}
                onChange={colorChange}
                placeholder="カラーコード"
              />
          </div>
          
          <div className="picker__swatches">
            <button className="clear_btn mb_1" onClick={coloClear}>クリア</button>
            <div
              className="picker__swatch"
              onClick={()=> colorSet("rgba(255, 255, 255, 0)")}
            >
              <img src="/transparent.jpg"/>
            </div>
            
            {PresetColors.map((color) => (
              <button
                key={color}
                className="picker__swatch"
                style={{ 
                  background: color,
                  outlineOffset: "3px",
                  outline: color == colorValue && "3px solid #fff"
                  // boxShadow: color == colorValue && "0 0 0 4px #ccc" 
                }}
                onClick={()=> colorSet(color)}
                // onMouseEnter={()=> PickerSwatch(color)}
              />
            ))}
          </div>
          
          <div className="picker">
            <RgbaStringColorPicker color={colorValue} onChange={setColorPicker}/>
            
            <input
              type="text"
              className="input"
              name={inputName}
              value={colorValue}
              onChange={colorChange}
              placeholder="カラーコード"
            />
          </div>

        </div>
        
        <div className="items_bar_bottom">
          <button className="save_btn">閉じる</button>
        </div>
        
      </div>
    </>
  );

});