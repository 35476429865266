import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';
import { PartBtn } from '@/part/type/PartBtn';


export const Card_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("カード１パーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];

  const cardCSS = css`
    ${outlineCSS(part)}
    box-shadow: ${part.class_name == "card_1-6" && `8px 8px 0 ${accentColors[0]}`};
    
    .card_img {
      aspect-ratio: ${img_0.aspect_ratio && "auto"};
      
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
      
      background: ${accentColors[0]};
      ${accentColors[1] && `
        background: linear-gradient(130deg, ${accentColors[0]} 30%, ${accentColors[1]} 100%);
      `}
    }
    p {
      ${textCSS(content)}
    }

  `
  
  return (
    <>
      <div 
        className={`card_1 ${part.class_name} ${part.add_class||""}`}
        css={cardCSS}
        id={part.add_id}
      >
        <div className="card_img">
          <PartImg
            obj={img_0}
            setObj={setImg_0}
          />
        </div>
        
        <div className="card_body">
          <div className="subtitle">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder="PAGE STOCK"
              clickHandler={subtitleHandler}
            />
          </div>
          
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </h3>
          
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              // placeholder={placeholder.content}
              placeholder="内容を入力..."
              clickHandler={contentHandler}
            />
          </p>
          
          {part.link && <PartBtn/>}
        </div>
      </div>
    </>
  );
  
});

// <PartImg
//   img_pc={img_pc}
//   setImg_pc={setImg_pc}
//   img_sp={img_sp}
//   setImg_sp={setImg_sp}
// />


  
  // const cardCSS = css`
  //   ${outlineCSS(part)}
    
  //   .card_img {
  //     img, video {
  //       ${imgCSS(img_0)}
  //     }
  //   }
  //   .title {
  //     color: ${part.font_color};
  //     ${textCSS(title)}
  //   }
  //   .subtitle {
  //     ${textCSS(subtitle)}
  //   }
  //   p {
  //     ${textCSS(content)}
  //   }
  // `