import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
// import { outlineStyle, inputStyle } from '@/part/type/Style';
import ReactStars from 'react-stars';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';


export const Rating_A = React.memo(({ part, setPart, imgs, textStates, textHandlers }) => {
  
  // console.log("レビューパーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  
  const ratingChanged = (newRating) => {
    setPart((prev)=> ({ ...prev, star: newRating}));
  }
  
  const ratingCSS = css`
    ${outlineCSS(part)}
    
    .icon {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
  `

  return (
    <>
      <div 
        className={`rating_1 ${part?.class_name} ${part?.add_class || ""}`}
        css={ratingCSS}
        id={part.add_id}
      >
        <div className="rating_icon">
          <div className="icon">
            <PartImg
              obj={img_0}
              setObj={setImg_0}
            />
          </div>
        
          <div className="rating_name">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder="Name"
              clickHandler={subtitleHandler}
            />
          </div>
        </div>
        
        <div className="rating_body">
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </h3>
          
          <div className="stars_box">
            <ReactStars
              count={5}
              size={32}
              color2={'#ffd700'} 
              value={part.star || 3}
              onChange={ratingChanged}
            />
            <div className="star_number">（{part.star || '3.0'}）</div>
          </div>
          
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              // placeholder={placeholder.content}
              placeholder="内容を入力..."
              clickHandler={contentHandler}
            />
          </p>
          
        </div>
      </div>
    </>
  );
  
});


// export const Rating_A = React.memo(({ part, InputChange, ratingChanged, setOpen }) => {
  
//   // console.log("レビューパーツレンダ");
  
//   const ratingCSS = css`
//     ${outlineCSS(part)}
//     .title {
//       ${fontCSS(part)}
//     }
//   `

//   return (
//     <>
//       <div 
//         className={`rating_1 ${part?.class_name} ${part?.add_class || ""}`}
//         // style={outlineStyle(part)} 
//         css={ratingCSS}
//         id={part.add_id}
//       >
//         <div className="rating_icon">
//           <div className="icon" onClick={()=>setOpen("Img")}>
//             <img src={part.img || "/no_img2.png"} alt={part.alt}/>
//           </div>
        
//           <div className="rating_name">
//             <input
//               type="text" 
//               name="subtitle" 
//               value={part.subtitle || ''} 
//               onChange={InputChange} 
//               placeholder="名前..."
//             />
//           </div>
//         </div>
        
//         <div className="rating_body">
//           <h3 className="title">
//             <TextareaAutosize 
//               type="text" 
//               name="title" 
//               value={part.title || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.title}
//             />
//           </h3>
          
//           <div className="stars_box">
//             <ReactStars
//               count={5}
//               size={32}
//               color2={'#ffd700'} 
//               value={part.star || 3}
//               onChange={ratingChanged}
//             />
//             <div className="star_number">（{part.star || '3.0'}）</div>
//           </div>
          
//           <p>
//             <TextareaAutosize
//               type="text"
//               // minRows={1}
//               name="content" 
//               value={part.content || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.content}
//             />
//           </p>
          
//         </div>
//       </div>
//     </>
//   );
  
// });