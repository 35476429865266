import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';
import { PartBtn } from '@/part/type/PartBtn';


export const Point_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("ポイント１パーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  const judge1_3or4 = part.class_name == "point_1-3" || part.class_name == "point_1-4"

  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  let pointCSS = css`
    ${outlineCSS(part)}
    
    .point_img {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }

    ${judge1_3or4 && accentColors[0] && `
      .point_img {
        img, video {
          box-shadow: 15px 15px 0 ${accentColors[0]};
          @media screen and (max-width: 500px) {
            box-shadow: 10px 10px 0 ${accentColors[0]};
          }
        }
      }
    `}
  `
  if (part.class_name == "point_4-1") {
    pointCSS = css`
      .point_body {
        ${outlineCSS(part)}
      }
      .point_img {
        img, video {
          ${imgCSS(img_0)}
        }
      }
      .title {
        color: ${part.font_color};
        ${textCSS(title)}
      }
      .subtitle {
        color: ${part.font_color};
        ${textCSS(subtitle)}
      }
      p {
        ${textCSS(content)}
      }
    `
  }

  return (
    <>
      <div 
        className={`point_1 ${part.class_name} ${part.add_class || ""}`}
        css={pointCSS}
        id={part.add_id}
      >
        <div className="point_body">
          
          <div className="subtitle">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder={placeholder.subtitle}
              clickHandler={subtitleHandler}
            />
          </div>
          
          <h3 className="title">
            <Textarea
              part_id={part.id}
              text_type="title"
              obj={title}
              setObj={setTitle}
              placeholder={placeholder.title}
              clickHandler={titleHandler}
            />
          </h3>
          
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              placeholder={placeholder.content}
              clickHandler={contentHandler}
            />
          </p>
          
          {part.link && <PartBtn/>}
        </div>
        
        <div className="point_img">
          <PartImg
            obj={img_0}
            setObj={setImg_0}
          />
        </div>
        
      </div>
    </>
  );
  
});
