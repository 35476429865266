import React from 'react';
import { Block } from '@/block/Block';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css'; // デフォルトのテーマを読み込んでいます
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

export const SectionSplide = React.memo(({ section, blocksState, type }) => {
  
  const { blocks } = blocksState
   
   
  const slideCss = css`
    .splide__slide {
      padding: 0;
      display: flex;
      align-items: stretch;
    }
    .slide_arrow_btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 0;
      background: ${type == "fade" ? "transparent" : "rgba(0,0,0,0.2)"};
    }
    .slide_arrow_btn:hover {
      background: rgba(0,0,0,0.4);
      border: 0;
      box-shadow: none;
    }
    .splide__arrow--prev {
      left: 0;
    }
    .splide__arrow--next {
      right: 0;
    }
    .splide__arrow svg {
      fill: ${type == "fade" ? "transparent" : "#fff"};
      height: 20px;
      width: 20px;
      margin: 0;
    }
    .splide__arrow:hover svg{
      fill: #fff;
    }
    
    @media screen and (max-width: 500px) {
      .slide_arrow_btn {
        width: 30px;
        height: 30px;
      }
      .splide__arrow svg {
        height: 15px;
        width: 15px;
      }
    }
  `

 return (
    <>
      <Splide
        css={slideCss}
        hasTrack={ false }
        options={{
          type: type,
          // padding: `${type == "loop" ? "7%" : "0"}`,
          rewind: true,
          autoplay: true, // 自動再生を有効
          interval: `${section.seconds||2}000`, // 自動再生の間隔を3秒に設定
          speed: "3500",
          drag: true,
          pauseOnHover: true,
          // padding: '5rem',
          classes: {
        		arrows: 'splide__arrows',
        		arrow : 'splide__arrow slide_arrow_btn',
        		prev  : 'splide__arrow--prev',
        		next  : 'splide__arrow--next',
          },
        }} 
        aria-label={`Section_${section.id}`}
      >
        <div className="splide__track">
		      <ul className="splide__list">
            {blocks.map((block, index) => {
              return (
                <li className="splide__slide" key={block.id}>
                  <Block 
                    block_obj={block} 
                    blocksState={blocksState} 
                    section_id={section.id} 
                    slide={true}
                    index={index}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </Splide>

    </>
  );
  
});

      // <style>{`
      //   .splide__slide {
      //     padding: 0;
      //     display: flex;
      //     align-items: stretch;
      //   }
      //   .slide_arrow_btn {
      //     width: 60px;
      //     height: 100%;
      //     border-radius: 0;
      //     background: transparent;
      //   }
      //   .slide_arrow_btn:hover {
      //     background: rgba(0,0,0,0.1);
      //     border: 0;
      //     box-shadow: none;
      //   }
      //   .splide__arrow--prev {
      //     left: 0;
      //   }
      //   .splide__arrow--next {
      //     right: 0;
      //   }
      //   .splide__arrow svg {
      //     fill: rgba(0,0,0,0.5);
      //     height: 3em;
      //     width: 3em;
      //     margin: 0;
      //   }
      //   .splide__arrow:hover svg{
      //     fill: #fff;
      //   }
      // `}</style>
      
