import React from "react"
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';


export const Notice_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log("お知らせパーツレンダ");
  const { title, setTitle } = textStates;
  const { titleHandler } = textHandlers
  
  const noticeCSS = css`
    ${outlineCSS(part)}
    ${textCSS(title)}
  `
  
  return (
    <>
      <div
        className={`notice_1 ${part.class_name} ${part.add_class||""}`}
        css={noticeCSS}
        id={part.add_id}
      >
        <Textarea
          part_id={part.id}
          text_type="title"
          obj={title}
          setObj={setTitle}
          placeholder="お知らせ内容を入力..."
          clickHandler={titleHandler}
        />
      </div>
    </>
  );
  
});

// export const Notice_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("お知らせパーツレンダ");
  
//   const noticeCSS = css`
//     ${outlineCSS(part)}
//     ${fontCSS(part)}
//   `
  
//   return (
//     <>
//       <div
//         className={`notice_1 ${part.class_name} ${part.add_class||""}`}
//         css={noticeCSS}
//         id={part.add_id}
//       >
//       　{part.class_name !== "sitemap_1" 
//           ?
//         　 <input
//               type="text" 
//               name="title" 
//               value={part.title ||''}
//               onChange={InputChange} 
//               placeholder="お知らせ内容を入力..."
//           />
//           :
//           <a>サイトマップ</a>
//       　}
        
//       </div>
//     </>
//   );
  
// });