import React from "react"
import { Link } from 'react-router-dom';
import { ContactForm } from '@/contact/ContactForm';
import { Recaptcha } from '@/contact/Recaptcha';
import { TopicPath } from '@/app/TopicPath';


export const Contact = ({ }) => {

  console.log("問い合わせ");
  
  return (
    <>
      <TopicPath topic="お問い合わせ"/>
      
      <div className="contact_form">
        <h1 className="H1 mb_2">お問い合わせ</h1>
        
        <Recaptcha>
          <ContactForm path="contact"/>
        </Recaptcha>
      </div>
    </>
  );
  
};

// <label>お名前</label>
// <input
//   className="input" 
//   type="text" 
//   name="name" 
//   value={ data.name || ''} 
//   onChange={InputChange} 
//   placeholder="お名前を入力..."
// />
 
// <label>メールアドレス(返信用)</label> 
// <input
//   className="input" 
//   type="email" 
//   name="email"
//   value={data.email ||''} 
//   onChange={InputChange} 
//   placeholder="メールアドレスを入力..."
// />

// <label>件名</label> 
// <input
//   className="input" 
//   type="text" 
//   name="title" 
//   value={data.title || ''} 
//   onChange={InputChange} 
//   placeholder="件名を入力..."
// />

// <label>メッセージ内容</label> 
// <TextareaAutosize
//   className="input" 
//   type="text"
//   minRows={6}
//   name="content" 
//   value={data.content || ''} 
//   onChange={InputChange} 
//   placeholder="メッセージ内容を入力..."
// />

// <div className="mt_4">
//   <button className="base_btn" onClick={HandleSubmit}>送信する</button>
// </div>