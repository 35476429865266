import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';


export const TextsData = React.memo(() => {
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ texts, setTexts ] = useState([]);
  console.log(texts);
  
  // データ取得
  const getTextsData = () => {
    client.get(`/api/v1/texts/duplicates`)
    .then(resp => {
      setTexts(resp.data);
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  
  // 削除
  const deleteText = (id) => {
    client.delete(`/api/v1/texts/${id}`)
    .then(resp => {
      setTexts((prev) =>
        prev.filter((obj) => obj.id !== id)
      );
      setToastObj({message: "削除しました。", color: "#ffedab"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】削除に失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
 
  return (
    <> 
      <h1>テキスト</h1>
      
      <button className="btn" onClick={getTextsData}>テキストデータ取得（{texts.length}）</button>

      <div className="block_card_box">
        {texts.map((text) => {
          return (
            <div className="block_card" key={text.id}>
              ID：{text.id}｜
              パーツID：{text.part_id}｜
              タイプ：{text.text_type}｜
              {text.content}
      
              <button className="btn" onClick={()=>deleteText(text.id)}>削除</button>
            </div>
          );
        })}
      </div>
    </>
  );
});

