import React, { useContext } from 'react';
import { StateContext } from '@/providers/StateProviders';
import { ToastContext } from '@/providers/ToastProvider';
import { SpinBtn } from '@/editerMenu/SpinBtn';
import { BiX } from "react-icons/bi";
import { useAutoSave } from '@/hooks/useAutoSave';

import { useRecoilState, useSetRecoilState } from 'recoil'
import { saveStatusAtom, sectionStatesAtom, blocksStatesAtom, openSidebarAtom } from '@/providers/Atoms';



export const SectionGlobalSetting = React.memo(({}) => {
  
  const { client } = useContext(StateContext);
  const { setToastObj } = useContext(ToastContext);
  
  const [ blocksStates, setBlocksStates ] = useRecoilState(blocksStatesAtom);
  const [ sectionStates, setSectionStates ] = useRecoilState(sectionStatesAtom);
  const setOpenSidebar = useSetRecoilState(openSidebarAtom);
  
  const section = sectionStates[0]
  const setSection = sectionStates[1]
  const blocks = blocksStates[0]
  const setBlocks = blocksStates[1]
  
  const setStates = (newSection) => {
    setSection(newSection);
    setSectionStates([ newSection, setSection ]);
  } 
  
  // console.log("セッション", section);
  // console.log("ブロックズ", blocks);

  // const setClickSection = useSetRecoilState(clickSectionAtom)
  const setSaveStatus = useSetRecoilState(saveStatusAtom);

  
  const InputChange = event => {
    const { name, value } = event.target;
    // setSection((prev)=> ({ ...prev, [name]: value }));
    const newSection = { ...section, [name]: value};
    setStates(newSection);
  };
  
  // 保存
  const SaveSection = () => {
    client.patch(`/api/v1/sections/${section.id}`, section)
    .then(resp => {
      setSaveStatus(false);
      console.log("保存完了");
      // setToastObj({message: "保存しました。", color: "#D1E7DD"});
    })
    .catch(e => {
      setToastObj({message: "【エラー】失敗しました。", color: "#ffedab"});
      console.log(e);
    });
  };
  
  // オートセーブ
  useAutoSave(section, SaveSection, 1000);
  
  
  // クリア
  const Clear = (name) => {
    // setSection((prev)=> ({ ...prev, [name]: null }));
    const newSection = { ...section, [name]: null };
    setStates(newSection);
  }

  // スライドブロックを複製追加
  const AddBlock = () => {
    const Copyblock= {
      section_id: section.id,
      block_id: blocks[0].id,
    }
    client.post('/api/v1/blocks/copy', Copyblock)
    .then(resp => {
      setBlocks((prev)=> ([...prev, resp.data]));
    })
    .catch(e => {
      setToastObj({message: `【失敗】${e.response.data}`, color: "#ffedab"});
      console.log(e)
    })
  };
  

  const SectionType = [
    {name: "スライダー", type:"Slide"},
    {name: "フェード", type:"Fade"},
    {name: "フローリング", type:"Flowing"},
    // {name: "スイッチ", type:"Switch"},
    // {name: "解除する", type: ""},
  ];
  
  
  const closeHandler = ()=> {
    setOpenSidebar("")
  };
  
  return (
    <>
      <div className="items_bar">
        
        <div className="items_bar_top">
          <div className="sidebar_title">
            スライド設定
            <div className="close_btn" onClick={closeHandler}><BiX/></div>
          </div>
        </div>
        
        <div className="items_box">
        
          <label>スライドタイプ
            <div className="btn_clear" type="button" onClick={()=>Clear("section_type")}>クリア</div>
          </label>
          <form className="radio_box">
            {SectionType.map((item) => {
              return (
                <label key={item.type}
                  className={`w_100 ${item.type == section.section_type && "is_on"}`}
                  // style={{border: item.type == section.section_type && "1px solid #00ff2c"}}
                >
                  <input
                    type="radio"
                    id={item.type}
                    name="section_type"
                    value={item.type}
                    onChange={InputChange}
                    checked={item.type == section.section_type}
                  />
                  {item.name}
                </label>
              );
            })}
          </form>
          
          <label>切替間隔（秒）
            <div className="btn_clear" type="button" onClick={()=>Clear("seconds")}>クリア</div>
          </label>
          <SpinBtn
            obj={section}
            setObj={setSection}
            column="seconds"
            objValue={section.seconds || 2}
            InputChange={InputChange}
            setStates={setStates}
          />
          
          { section.section_type &&
            <>
              <div className="form_field">
                <label>スライドするブロックを追加</label>
                <button className="btn_line w_100" onClick={AddBlock}>複製してブロックを追加</button>
              </div>
            </>
          }
          <label>ブロック数：{blocks.length}</label> 
          
        </div>
        
        <div className="items_bar_bottom">
        </div>
      
      </div>
    </>
  );
});