import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import { StateContext } from '@/providers/StateProviders';
import { HomeNotices } from '@/notice/HomeNotices';
import { ProductCard } from '@/product/ProductCard';
import { BiPlus } from "react-icons/bi";


export const Dashboard = React.memo(({ productsState, plansState, notices }) => {

  // console.log("ホームレンダリング");
  const { currentUser } = useContext(StateContext);
  const { products } = productsState
  const { plans } = plansState
  const [ warning, setWarning ] = useState(false);
  
  // 作成可能プロダクトをジャッジ
  const productsJudg = plans.length >= products.length || currentUser.admin == true
  // const productsJudg = true
  
  const warningSet = (page) => {
    setWarning(true);
  };

  return (
    <> 
      <div className="main_layer"></div>
      <HomeNotices notices={notices}/>
      
      <div className="step_guide" style={{padding: "1.5rem 2rem"}}>
        <a href="https://manual.pstock.jp/edit-page/" target="_blank" rel="noopener noreferrer">「作成マニュアル」はこちら</a>
      </div>
        
      <h2 className="H2 color_white">プロダクト</h2>
      <div className="product_card_box">
        
        <div className="new_product_card">
          {productsJudg
            ?
              <Link to="/product/new">
                <button className="btn_add">
                  <span><BiPlus/>プロダクトの作成</span>
                </button>
              </Link>
            :
              <button className="btn_add" onClick={warningSet} 
                // style={{background:"#ccc", border:"solid 1px #ccc"}}
              >
                <span><BiPlus/>プロダクトの作成</span>
              </button>
          }
          
          {warning &&
            <div className="advice_box mt_2">
              新しいプロダクトを作成するには、既存プロダクトのプランを「有料プラン」に変更する必要があります。
            </div>
          }
        </div> 
        
        {products.map((product) => {
          return (
            <ProductCard product={product} key={product.id}/>
          );
        })}
      </div>
      
      {products == ""
        ?
          <>
            <div className="step_guide">
              <h2 className="H2 color_white">作成ガイド</h2>
              <div className="step">ステップ１</div>
              上記のボタンから<span className="color_key">「プロダクトを作成」</span>してください。
            </div>
          </>
        :
          <>
            <div className="step_guide">
              <div className="step">ステップ２</div>
              ダッシュボードへ移動し、<span className="color_key">「ページの編集」</span>に進んでください。
            </div>
          </>
      }
      
      <div className="card_1 card_3-2">
        <div className="card_img" style={{aspectRatio:"1/1"}}>
          <img src="/29800.png"/>
        </div>
        
        <div className="card_body">
          {/*<div className="subtitle">【全額返金】保証付き</div>*/}
          <h3 className="title" style={{fontSize:"24px"}}>オリジナルホームページ「29,800円～」作成します！ </h3>
          <p>制作のプロがあなたに代わってホームページを作成します。万が一、気に入らない場合は制作費用を全額返金いたします。
          「もし微妙な出来上がりになったらどうしよう？」とご心配な方にも安心です。
          </p>
          <a href="https://pstock.jp/homepage-creation" 
            className="part_btn" target="_blank" rel="noopener noreferrer"
            style={{fontSize:"14px"}}
          >
          詳しくはこちら
          </a>
        </div>
      </div>
      
      <h2 className="H2_line">LINEでお問い合わせ</h2>
      <p>ご不明点がございましたら、LINEからでも気軽にお問い合わせください。</p>
      <a href="https://lin.ee/xDstAW0" target="_blank" rel="noopener noreferrer">
        <button className="btn mr_2">LINE公式アカウントへ</button>
      </a>
      <Link to="/contact" className="row">
        <button className="">お問い合わせへ</button>
      </Link>
    </>
  );
});

// <div className="new_product_card">
//   <Link to="/product/new">
//     <button className="btn_add" disabled={!productsJudg}>
//       <span><BiPlus/>プロダクトの作成</span>
//     </button>
//   </Link>
  
//   {!productsJudg &&
//     <div className="advice_box mt_2">
//       新しいプロダクトを作成するには、既存プロダクトのプランを「有料プラン」に変更する必要があります。
//     </div>
//   }
// </div> 