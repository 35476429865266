import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';



export const Comment_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("コメントパーツレンダ");
  const { subtitle, setSubtitle, content, setContent } = textStates;
  const { subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  
  const commentCSS = css`
    .icon {
      img, video {
        ${imgCSS(img_0)}
      }
    }
    .comment_body {
      ${outlineCSS(part)} 
    }
    .comment_name {
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
  `
  
  // const iconStyle = {
  //   borderColor: part?.border_color && `${part.border_color}`,
  //   borderWidth : part?.border && `${part.border}px`,
  // }

  return (
    <>
      <div 
        className={`comment_1 ${part.class_name} ${part.add_class || ""}`}
        css={commentCSS}
        id={part.add_id}
      >
        <div className="comment_icon">
          <div className="icon">
            <PartImg
              obj={img_0}
              setObj={setImg_0}
            />
          </div>
          
          <div className="comment_name">
            <Textarea 
              part_id={part.id}
              text_type="subtitle"
              obj={subtitle}
              setObj={setSubtitle}
              placeholder="Name"
              clickHandler={subtitleHandler}
            />
          </div>
        </div>
        
        <div className="comment_body">
          <p>
            <Textarea
              part_id={part.id}
              text_type="content"
              obj={content}
              setObj={setContent}
              // placeholder={placeholder.content}
              placeholder="内容を入力..."
              clickHandler={contentHandler}
            />
          </p>
        </div>
      </div>
    </>
  );
  
});


  // const commentCSS = css`
  //   .icon {
  //     img, video {
  //       ${imgCSS(img_0)}
  //     }
  //   }
    
  //   .comment_body {
  //     ${outlineCSS(part)} 
  //   }
  //   .comment_name {
  //     ${textCSS(subtitle)}
  //   }
  //   p {
  //     ${textCSS(content)}
  //   }
  // `


// export const Comment_A = React.memo(({ part, InputChange, setOpen }) => {
  
//   // console.log("コメントパーツレンダ");
  
//   const commentCSS = css`
//     .comment_body {
//       ${outlineCSS(part)} 
//     }
//     p {
//       ${fontCSS(part)}
//     }
//   `
  
//   // const iconStyle = {
//   //   borderColor: part?.border_color && `${part.border_color}`,
//   //   borderWidth : part?.border && `${part.border}px`,
//   // }

//   return (
//     <>
//       <div 
//         className={`comment_1 ${part.class_name} ${part.add_class || ""}`}
//         css={commentCSS}
//         id={part.add_id}
//       >
//         <div className="comment_icon">
//           <div className="icon" onClick={()=>setOpen("Img")}>
//             <img src={part.img || placeholder.img}/>
//           </div>
          
//           <div className="comment_name">
//             <input
//               type="text" 
//               name="subtitle" 
//               value={part.subtitle || ''} 
//               onChange={InputChange} 
//               placeholder="名前..."
//             />
//           </div>
//         </div>
        
//         <div className="comment_body">
//           <p>
//             <TextareaAutosize
//               type="text"
//               // style={inputStyle(part)}
//               minRows={2}
//               name="content" 
//               value={part.content || ''} 
//               onChange={InputChange} 
//               placeholder={placeholder.content}
//             />
//           </p>
//         </div>
//       </div>
//     </>
//   );
  
// });