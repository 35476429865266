import React, { useState } from "react"
// import TextareaAutosize from 'react-textarea-autosize';
// import { ImgVideo } from '@/app/ImgVideo';
import { placeholder } from '@/app/DefaultData';
import { textCSS, imgCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartImg } from '@/part/PartImg';


export const Image_A = React.memo(({ part, imgs, textStates, textHandlers }) => {
  
  // console.log("画像パーツ１レンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  const [ img_0, setImg_0 ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_pc, setImg_pc ] = useState(imgs[0] || {part_id: part.id, img: null});
  // const [ img_sp, setImg_sp ] = useState(imgs_sp[0] || {part_id: part.id, img: null});
  
  // useEffect(() => {
  //   if(part.class_name =="image_1-2") {
  //     setPart({...part, width: 50 });
  //     console.log("画像パーツ１レンダ");
  //   }
  // }, [part.class_name]);
  
  const Judg = /.jpg|.jpeg|.JPG|.JPEG|.png|.gif|.tiff|.tif|.webp|.svg|.mp4|.ogv|.webm/.test(img_0.img)
  
  let backColors = part.back_color ? part.back_color.split("／") : [];
  
  const imageCSS = css`
    background: ${backColors[0]};
    border-radius: ${part.radius && `${part.radius}px`};
    padding: ${part.padding && `${part.padding}px`};
    text-align: ${part.align};
      
    .image_img {
      border-color: ${part.border_color};
      border-width : ${part.border && `${part.border}px`};
      border-radius: ${part.radius && `${part.radius}px`};
      box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
      height: ${part.class_name == "image_2-1" && "auto"};
      height: ${!Judg && "auto"};
      
      img, video {
        ${imgCSS(img_0)}
      }
    }

    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
    }
    p {
      ${textCSS(content)}
    }
  `

  
  return (
    <div 
      className={`image_1 ${part.class_name} ${part.add_class||""}`}
      css={imageCSS}
      id={part.add_id}
    >
      <div className="image_img">
        <PartImg
          obj={img_0}
          setObj={setImg_0}
        />
      </div>
      
      <div className="text_layer">
        <div className="subtitle">
          <Textarea 
            part_id={part.id}
            text_type="subtitle"
            obj={subtitle}
            setObj={setSubtitle}
            placeholder={placeholder.subtitle}
            clickHandler={subtitleHandler}
          />
        </div>
        
        <h3 className="title">
          <Textarea
            part_id={part.id}
            text_type="title"
            obj={title}
            setObj={setTitle}
            placeholder={placeholder.title}
            clickHandler={titleHandler}
          />
        </h3>
      </div>
            
      <p>
        <Textarea
          part_id={part.id}
          text_type="content"
          obj={content}
          setObj={setContent}
          placeholder="内容を入力..."
          clickHandler={contentHandler}
        />
      </p>
    </div>
  );
  
});

  // const imageCSS = css`
  //   background: ${backColors[0]};
  //   border-radius: ${part.radius && `${part.radius}px`};
  //   padding: ${part.padding && `${part.padding}px`};
  //   text-align: ${part.align};
      
  //   .image_img {
  //     border-color: ${part.border_color};
  //     border-width : ${part.border && `${part.border}px`};
  //     border-radius: ${part.radius && `${part.radius}px`};
  //     box-shadow: ${part.box_shadow && `0px 2px ${part.box_shadow}px rgba(0, 0, 0, 0.2)`};
  //     height: ${part.class_name == "image_2-1" && "auto"};
  //     height: ${!Judg && "auto"};
  //   }
  //   .image_img {
  //     img, video {
  //       ${imgCSS(img_0)}
  //     }
  //   }
  //   .title {
  //     color: ${part.font_color};
  //     ${textCSS(title)}
  //   }
  //   .subtitle {
  //     color: ${part.font_color};
  //     ${textCSS(subtitle)}
  //   }
  //   p {
  //     ${textCSS(content)}
  //   }
  // `