import React from 'react';
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { placeholder } from '@/app/DefaultData';


export const Step_A = React.memo(({ part, InputChange, textStates, textHandlers }) => {
  
  // console.log("ナビバーパーツレンダ");
  
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers

  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const stepCSS = css`
    ${outlineCSS(part)}
    
    ${accentColors[0] && `
      :before {
        background: ${accentColors[0]};
        box-shadow: 0 0 0 2px ${accentColors[0]};
      }
    `}
    
    ${part.border_color && `
      :after {
        border-left: dotted 3px ${part.border_color};
      }
    `}
    
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
    }
    .subtitle {
      color: ${part.font_color};
      ${textCSS(subtitle)}
    }
    p {
      color: ${part.font_color};
      ${textCSS(content)}
    }
    
    ${part.class_name == "step_2-1" && `
      .subtitle {
        background: ${accentColors[0]};
      }
    `}

  `
  
  
  return (
    <>
      <div
        className={`step_1 ${part?.class_name} ${part?.add_class}`}
        css={stepCSS}
        id={part.add_id}
      >
        <div className="subtitle">
          <Textarea 
            part_id={part.id}
            text_type="subtitle"
            obj={subtitle}
            setObj={setSubtitle}
            placeholder="ステップ１"
            clickHandler={subtitleHandler}
          />
        </div>
        
        <div className="title">
          <Textarea
            part_id={part.id}
            text_type="title"
            obj={title}
            setObj={setTitle}
            placeholder={placeholder.title}
            clickHandler={titleHandler}
          />
        </div>
        
        <p>
          <Textarea
            part_id={part.id}
            text_type="content"
            obj={content}
            setObj={setContent}
            placeholder={placeholder.content}
            clickHandler={contentHandler}
          />
        </p>
        
      </div>
    </>
  );
  
});