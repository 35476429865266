import React from "react"
import { ContactForm } from '@/contact/ContactForm';
import { Recaptcha } from '@/contact/Recaptcha';


export const Inquiry = ({ }) => {

  console.log("問い合わせ");
  
  return (
    <>
      <div className="contact_form">
        <h1 className="H1 mb_2">お問い合わせ</h1>
        
        <Recaptcha>
          <ContactForm path="contact"/>
        </Recaptcha>
      </div>
    </>
  );
  
};