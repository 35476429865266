import React from "react"
// import TextareaAutosize from 'react-textarea-autosize';
import { placeholder } from '@/app/DefaultData';
import { outlineCSS, textCSS } from '@/part/type/Style';
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Textarea } from '@/text/Textarea';
import { PartBtn } from '@/part/type/PartBtn';


export const Box_A = React.memo(({ part, textStates, textHandlers }) => {
  
  // console.log("ボックスパーツレンダ");
  const { title, setTitle, subtitle, setSubtitle, content, setContent } = textStates;
  const { titleHandler, subtitleHandler, contentHandler } = textHandlers
  
  let accentColors = part.sub_color ? part.sub_color.split("／") : [];
  
  const boxCSS = css`
    ${outlineCSS(part)}
    box-shadow: ${part.class_name == "box_2-5" && `8px 8px 0 ${accentColors[0]}`};
    
    .title {
      color: ${part.font_color};
      ${textCSS(title)}
      
      border-color: ${part.class_name == "box_1-2" && `${accentColors[0]}`};
      border-color: ${part.class_name == "box_1-3" && `${accentColors[0]}`};
    }
    .subtitle {
      ${textCSS(subtitle)}
      border-color: ${part.border_color};
      border-width : ${part.border && `${part.border}px`};
      background: ${accentColors[0]};
      ${accentColors[1] && `
        background: linear-gradient(130deg, ${accentColors[0]} 30%, ${accentColors[1]} 100%);
      `}
    }
    p {
      ${textCSS(content)}
    }
  `

  return (
    <>
      <div
        className={`box_1 ${part.class_name} ${part.add_class||""}`}
        css={boxCSS}
        id={part.add_id}
      >
        <div className="subtitle">
          <Textarea 
            part_id={part.id}
            text_type="subtitle"
            obj={subtitle}
            setObj={setSubtitle}
            placeholder={placeholder.subtitle}
            clickHandler={subtitleHandler}
          />
        </div>
        
        <h3 className="title">
          <Textarea
            part_id={part.id}
            text_type="title"
            obj={title}
            setObj={setTitle}
            placeholder={placeholder.title}
            clickHandler={titleHandler}
          />
        </h3>
        
        <p>
          <Textarea
            part_id={part.id}
            text_type="content"
            obj={content}
            setObj={setContent}
            // placeholder={placeholder.content}
            placeholder="内容を入力..."
            clickHandler={contentHandler}
          />
        </p>
        
        {part.link && <PartBtn/>}
      </div>
    </>
  );
  
});

// export const Box_A = React.memo(({ part, InputChange }) => {
  
//   // console.log("ボックスパーツレンダ");
  
//   const boxCSS = css`
//     ${outlineCSS(part)}
//     .title {
//       color: ${part.font_color};
//       color: ${part.border_color};
//     }
//     p {
//       ${fontCSS(part)}
//     }
//   `

//   return (
//     <>
//       <div
//         className={`box_1 ${part.class_name} ${part.add_class||""}`}
//         css={boxCSS}
//         id={part.add_id}
//       >
//         <h3 className="title">
//           <TextareaAutosize 
//             type="text" 
//             name="title" 
//             value={part?.title || ''} 
//             onChange={InputChange} 
//             placeholder={placeholder.title}
//           />
//         </h3>
        
//         <p>
//           <TextareaAutosize
//             type="text"
//             // minRows={1}
//             name="content" 
//             value={part?.content || ''} 
//             onChange={InputChange} 
//             placeholder={placeholder.content}
//           />
//         </p>
        
//         {part.link &&
//           <a className="part_btn">MORE</a>
//         }
//       </div>
//     </>
//   );
  
// });